form #card_number {
    background-image: url(/img/images.png), url(/img/images.png);
    background-position: 2px -121px, 260px -61px;
    background-size: 120px 361px, 120px 361px;
    background-repeat: no-repeat;
    padding-left: 54px;
}
form #card_number.visa {
    background-position: 2px -163px, 260px -61px;
}
form #card_number.visa_electron {
    background-position: 2px -205px, 260px -61px;
}
form #card_number.mastercard {
    background-position: 2px -247px, 260px -61px;
}
form #card_number.maestro {
    background-position: 2px -289px, 260px -61px;
}
form #card_number.discover {
    background-position: 2px -331px, 260px -61px;
}
form #card_number.valid.visa {
    background-position: 2px -163px, 260px -87px;
}
form #card_number.valid.visa_electron {
    background-position: 2px -205px, 260px -87px;
}
form #card_number.valid.mastercard {
    background-position: 2px -247px, 260px -87px;
}
form #card_number.valid.maestro {
    background-position: 2px -289px, 260px -87px;
}
form #card_number.valid.discover {
    background-position: 2px -331px, 260px -87px;
}